export enum FrontendLayoutsEnum {
  default = "default",
  login = "login",
  error = "error",
}

export const FrontendLayoutToFileMap: Record<FrontendLayoutsEnum, string> = {
  default: "FrontendLayoutDefault.vue",
  login: "FrontendLayoutLogin.vue",
  error: "FrontendLayoutError.vue",
};
