import {FrontendLayoutsEnum} from "@/frontend/app/layouts/types";
import type {FrontendRouteRecordType} from "@/frontend/app/router/types";
import {ESubscriptionTariffOptionCode} from "@/frontend/entities/subscription";

export const cardRoutes = [
    {
        path: '/card',
        name: 'cards.create',
        component: () => import("@/frontend/pages/cards/CardCreate.vue"),
        meta: {
            layout: FrontendLayoutsEnum.default,
            title: 'Creating visiting card',
            requiresAuth: true,
            needSubscriptionOption: ESubscriptionTariffOptionCode.VISIT_CARD
        },
    },
    {
        path: '/card/:id',
        name: 'cards.edit',
        component: () => import("@/frontend/pages/cards/CardEdit.vue"),
        meta: {
            layout: FrontendLayoutsEnum.default,
            title: 'Editing visiting card',
            requiresAuth: true,
            needSubscriptionOption: ESubscriptionTariffOptionCode.VISIT_CARD
        },
    },
] as const satisfies readonly FrontendRouteRecordType[];
