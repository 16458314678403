import './bootstrap';
import {type App, createApp} from 'vue';
import {VueQueryPlugin, type VueQueryPluginOptions} from "@tanstack/vue-query";
import '@/frontend/app/assets/scss/style.scss';
import TheApp from '@/frontend/app/App.vue';
import router from '@/frontend/app/router';
import {createPinia} from 'pinia';
import {TheRouterLink} from "@/frontend/shared/routerLink";
import {TranslatePlugin} from "@/frontend/entities/locale";
import {Html2CanvasPlugin} from 'vue3-html2canvas';
import {createVfm, type Vfm} from "vue-final-modal";
import {configure} from 'vee-validate';
import VueSocialSharing from "vue-social-sharing";
import { initYandexMetrika } from 'yandex-metrika-vue3';


configure({
    validateOnModelUpdate: false,
});

const vueQueryPluginOptions: VueQueryPluginOptions = {
    queryClientConfig: {
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
            },
        },
    },
}

const vfm: Vfm = createVfm();
const app: App = createApp(TheApp);
app.use(VueQueryPlugin, vueQueryPluginOptions);
app.component('TheRouterLink', TheRouterLink);
app.use(vfm);
app.use(router);
app.use(createPinia());
app.use(TranslatePlugin);
app.use(Html2CanvasPlugin);
app.use(VueSocialSharing);

/* @ts-ignore */
app.use(initYandexMetrika, {
    id: import.meta.env.VITE_YANDEX_METRIKA_ID,
    env: 'production'
});

app.mount('#app');
