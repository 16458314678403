import {FrontendLayoutsEnum} from "@/frontend/app/layouts/types";
import type {FrontendRouteRecordType} from "@/frontend/app/router/types";
import {ESubscriptionTariffOptionCode} from "@/frontend/entities/subscription";

export const selfLivingRoutes = [
    {
        path: '/self-living',
        name: 'self-lively-photos.create',
        component: () => import("@/frontend/pages/self-livings/SelfLivingCreate.vue"),
        meta: {
            layout: FrontendLayoutsEnum.default,
            title: 'Creating self-living',
            requiresAuth: true,
            needSubscriptionOption: ESubscriptionTariffOptionCode.SELF_LIVING
        },
    },
    {
        path: '/self-living/:id',
        name: 'self-lively-photos.edit',
        component: () => import("@/frontend/pages/self-livings/SelfLivingEdit.vue"),
        meta: {
            layout: FrontendLayoutsEnum.default,
            title: 'Editing self-living',
            requiresAuth: true,
            needSubscriptionOption: ESubscriptionTariffOptionCode.SELF_LIVING
        },
    },
] as const satisfies readonly FrontendRouteRecordType[];
