import {FrontendLayoutsEnum} from "@/frontend/app/layouts/types";
import type {FrontendRouteRecordType} from "@/frontend/app/router/types";

export const paymentTransactionRoutes = [
    {
        path: '/payment-transactions',
        name: 'payment-transactions.index',
        component: () => import("@/frontend/pages/payment-transactions/PaymentTransactionIndex.vue"),
        meta: {
            layout: FrontendLayoutsEnum.default,
            title: 'Recharge History',
            requiresAuth: true
        }
    },
] as const satisfies readonly FrontendRouteRecordType[];
