import {useAxios} from "@vueuse/integrations/useAxios";
import {authHeader, useAuthStore} from "@/frontend/entities/auth";
import type {StrictUseAxiosReturn} from "@vueuse/integrations";
import {useAxiosOptions} from "@/frontend/entities/app/lib/useAxiosOptions";
import {useCurrencyRateStore} from "@/frontend/entities/app";

const API_PATH = '/api';

const useAppService = () => {
    const init = async (): Promise<StrictUseAxiosReturn<any, any, any>> => {
        const {execute} = useAxios(
            API_PATH + '/init',
            {
                method: 'GET',
                headers: authHeader()
            },
            useAxiosOptions
        );

        const response = await execute();
        console.log('init response');

        if (response.data.value?.data?.viewer) {
            const {setUser} = useAuthStore();
            await setUser(response.data.value.data.viewer);
        }

        return response;
    }
    const getCurrencyRate = async (): Promise<StrictUseAxiosReturn<any, any, any>> => {
        const {execute} = useAxios(
            API_PATH + '/currency-rate',
            {
                method: 'GET',
                headers: authHeader()
            },
            useAxiosOptions
        );

        const response = await execute();
        console.log('getCurrencyRate response');

        if (response.data.value?.data) {
            const {setCurrencyRate} = useCurrencyRateStore();
            await setCurrencyRate(response.data.value.data);
        }

        return response;
    }

    return {init, getCurrencyRate};
}

export default useAppService;
