import {FrontendLayoutsEnum} from "@/frontend/app/layouts/types";
import type {FrontendRouteRecordType} from "@/frontend/app/router/types";

export const livelyPhotoRoutes = [
    {
        path: '/photo-book',
        name: 'lively-photos.create',
        component: () => import("@/frontend/pages/lively-photos/LivelyPhotoCreate.vue"),
        meta: {
            layout: FrontendLayoutsEnum.default,
            title: 'Creating lively photos',
            requiresAuth: true
        },
    },
    {
        path: '/photo-book/:id',
        name: 'lively-photos.edit',
        component: () => import("@/frontend/pages/lively-photos/LivelyPhotoEdit.vue"),
        meta: {
            layout: FrontendLayoutsEnum.default,
            title: 'Editing lively photos',
            requiresAuth: true
        },
    },
] as const satisfies readonly FrontendRouteRecordType[];
