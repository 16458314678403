import {FrontendLayoutsEnum} from "@/frontend/app/layouts/types";
import type {FrontendRouteRecordType} from "@/frontend/app/router/types";

export const myOrderRoutes = [
    {
        path: '/my-orders',
        name: 'orders.index',
        component: () => import("@/frontend/pages/my-orders/MyOrderIndex.vue"),
        meta: {
            layout: FrontendLayoutsEnum.default,
            title: 'My orders',
            requiresAuth: true
        }
    },
    {
        path: '/my-orders/:id',
        name: 'orders.folder',
        component: () => import("@/frontend/pages/my-orders/MyOrderFolder.vue"),
        meta: {
            layout: FrontendLayoutsEnum.default,
            title: 'My orders | Folder',
            requiresAuth: true
        }
    },
] as const satisfies readonly FrontendRouteRecordType[];
