<script setup
        lang="ts">
import AppLayout from "@/frontend/app/layouts/FrontendLayout.vue";
import {watch} from "vue";
import {useRoute} from "vue-router";
import {ModalsContainer} from "vue-final-modal";
import {useLocaleStore} from "@/backend/entities/locale";
import {storeToRefs} from "pinia";

const route = useRoute();

const LocaleStore = useLocaleStore();
const {$t} = LocaleStore;
const {$locale: locale} = storeToRefs(LocaleStore);

watch(locale, () => {
    document.title = $t(route.meta.title ? route.meta.title as unknown as string : import.meta.env.VITE_APP_NAME)
});
</script>

<template>
    <AppLayout>
        <RouterView/>
    </AppLayout>
    <ModalsContainer/>
</template>

<style scoped
       lang="scss">

</style>
