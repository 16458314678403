import {FrontendLayoutsEnum} from "@/frontend/app/layouts/types";
import type {FrontendRouteRecordType} from "@/frontend/app/router/types";
import {ESubscriptionTariffOptionCode} from "@/frontend/entities/subscription";

export const leafletRoutes = [
    {
        path: '/leaflet',
        name: 'leaflets.create',
        component: () => import("@/frontend/pages/leaflets/LeafletCreate.vue"),
        meta: {
            layout: FrontendLayoutsEnum.default,
            title: 'Creating leaflet',
            requiresAuth: true,
            needSubscriptionOption: ESubscriptionTariffOptionCode.LEAFLET
        },
    },
    {
        path: '/leaflet/:id',
        name: 'leaflets.edit',
        component: () => import("@/frontend/pages/leaflets/LeafletEdit.vue"),
        meta: {
            layout: FrontendLayoutsEnum.default,
            title: 'Editing leaflet',
            requiresAuth: true,
            needSubscriptionOption: ESubscriptionTariffOptionCode.LEAFLET
        },
    },
] as const satisfies readonly FrontendRouteRecordType[];
