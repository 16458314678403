import {FrontendLayoutsEnum} from "@/frontend/app/layouts/types";
import type {FrontendRouteRecordType} from "@/frontend/app/router/types";

export const authRoutes = [
    {
        path: '/login',
        name: 'auth.login',
        component: () => import("@/frontend/pages/auth/Login.vue"),
        meta: {
            layout: FrontendLayoutsEnum.login,
            title: 'Login',
            requiresAuth: false
        }
    },
    {
        path: '/enroll',
        name: 'auth.register',
        component: () => import("@/frontend/pages/auth/Register.vue"),
        meta: {
            layout: FrontendLayoutsEnum.login,
            title: 'Registration',
            requiresAuth: false
        }
    },
    {
        path: '/forgot-password',
        name: 'auth.forgot-password',
        component: () => import("@/frontend/pages/auth/ForgotPassword.vue"),
        meta: {
            layout: FrontendLayoutsEnum.login,
            title: 'Password recovery',
            requiresAuth: false
        }
    },
    {
        path: '/reset-password',
        name: 'auth.reset-password',
        component: () => import("@/frontend/pages/auth/ResetPassword.vue"),
        meta: {
            layout: FrontendLayoutsEnum.login,
            title: 'Reset password',
            requiresAuth: false
        }
    },
] as const satisfies readonly FrontendRouteRecordType[];
