import type { RouteLocationNormalized } from "vue-router";
import { FrontendLayoutsEnum, FrontendLayoutToFileMap } from "@/frontend/app/layouts/types";

export async function loadLayoutMiddleware(route: RouteLocationNormalized): Promise<void> {
  const { layout } = route.meta;
  const normalizedLayoutName: FrontendLayoutsEnum = layout as unknown as FrontendLayoutsEnum || FrontendLayoutsEnum.default;
  const fileName = FrontendLayoutToFileMap[normalizedLayoutName];
  const fileNameWithoutExtension = fileName.split(".vue")[0];

  const component = await import(`../../layouts/${fileNameWithoutExtension}.vue`);
  route.meta.layoutComponent = component.default;
}
