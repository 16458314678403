import type {RouteLocationRaw, RouteRecordRaw} from 'vue-router';
import {createRouter, createWebHistory} from 'vue-router';
import type {FrontendRouteRecordType, RouteToType} from "@/frontend/app/router/types";
import {loadLayoutMiddleware} from "@/frontend/app/router/middleware/loadLayout.middleware";
import {FrontendLayoutsEnum} from "@/frontend//app/layouts/types";
import {useLocaleStore} from "@/frontend/entities/locale";
import {componentRoutes} from "@/frontend/app/router/entities/componentRoutes";
import {companyRoutes} from "@/frontend/app/router/entities/companyRoutes";
import {livelyPhotoRoutes} from "@/frontend/app/router/entities/livelyPhotoRoutes";
import {cardRoutes} from "@/frontend/app/router/entities/cardRoutes";
import {leafletRoutes} from "@/frontend/app/router/entities/leafletRoutes";
import {calendarRoutes} from "@/frontend/app/router/entities/calendarRoutes";
import {ref} from "vue";
import {useAuthStore} from "@/frontend/entities/auth";
import {useAppService} from "@/frontend/entities/app";
import {EUserRole} from "@/frontend/entities/user";
import {authRoutes} from "@/frontend/app/router/entities/authRoutes";
import {myOrderRoutes} from "@/frontend/app/router/entities/myOrderRoutes";
import {paymentTransactionRoutes} from "@/frontend/app/router/entities/paymentTransactionRoutes";
import {selfLivingRoutes} from "@/frontend/app/router/entities/selfLivingRoutes";
import {storeToRefs} from "pinia";
import {getRedirectQuery} from "@/frontend/app/lib/utils";

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import("@/frontend/pages/Home.vue"),
        meta: {
            layout: FrontendLayoutsEnum.default,
            title: 'Augmented Reality Designer',
            requiresAuth: true
        }
    },
    ...authRoutes,
    ...livelyPhotoRoutes,
    ...cardRoutes,
    ...leafletRoutes,
    ...calendarRoutes,
    ...selfLivingRoutes,
    ...companyRoutes,
    ...componentRoutes,
    ...myOrderRoutes,
    ...paymentTransactionRoutes,
    {
        path: '/tariffs',
        name: 'tariffs.index',
        component: () => import("@/frontend/pages/tariffs/TariffsIndex.vue"),
        meta: {
            layout: FrontendLayoutsEnum.default,
            title: 'Tariffs',
            requiresAuth: true
        }
    },
    {
        path: '/referral-program',
        name: 'referral-programs.index',
        component: () => import("@/frontend/pages/referral-program/ReferralProgramIndex.vue"),
        meta: {
            layout: FrontendLayoutsEnum.default,
            title: 'Referral program',
            requiresAuth: true
        }
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: () => import('@/frontend/pages/NotFound.vue'),
        meta: {
            layout: FrontendLayoutsEnum.default,
            title: '404 Page not found',
            requiresAuth: false
        }
    },
] as const satisfies readonly FrontendRouteRecordType[];

export type RoutesType = typeof routes;

const router = createRouter({
    history: createWebHistory(),
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {top: 0}
        }
    },
    routes: routes as unknown as RouteRecordRaw[]
})

router.beforeEach(loadLayoutMiddleware);

let isAppInitialized = ref(false);
const AppService = useAppService();

router.beforeResolve(async (to, from) => {
    const AuthStore = useAuthStore();
    const {isAuth} = storeToRefs(AuthStore);

    if (!isAppInitialized.value) {
        try {
            await AppService.init();
            if (AuthStore.userHasRole([EUserRole.ADMIN, EUserRole.MANAGER])) {
                location.href = '/admin';
                return {name: 'auth.login'};
            } else if (isAuth.value && !to.meta.requiresAuth) {
                return from.query?.redirect ? {path: from.query.redirect as string} : {name: 'home'};
            } else if (!from.name && isAuth.value && to.meta.needSubscriptionOption && !AuthStore.userSubscriptionHasOption(to.meta.needSubscriptionOption)) {
                return {name: 'home'};
            }
            isAppInitialized.value = true;
        } catch (e) {
            isAppInitialized.value = true;
            if (to.meta.requiresAuth) {
                return {name: 'auth.login', query: {redirect: getRedirectQuery(to)}};
            }
        } finally {
            document.getElementById('app')?.classList?.remove('loading');
        }
    }

    if (from.name && to.meta.requiresAuth) {
        try {
            return isAuth.value;
        } catch (error) {
            throw error;
        }
    }
    return true;
})

router.beforeEach(async (to, from) => {
    console.log('to', to);
    const AuthStore = useAuthStore();

    const {isAuth, isLockedMode, isSubscriptionPaymentType, user} = storeToRefs(AuthStore);

    if (isAuth.value && to.meta.needSubscriptionOption && !AuthStore.userSubscriptionHasOption(to.meta.needSubscriptionOption)) {
        if (!from.name) {
            return {name: 'home'};
        }
        return false;
    }

    if (isAuth.value && isLockedMode.value) {
        if (isSubscriptionPaymentType.value && !['tariffs.index', 'orders.index'].includes(to.name as string)) {
            return {name: 'tariffs.index'};
        } else if (!isSubscriptionPaymentType.value && to.name !== 'home') {
            return {name: 'home'};
        }
    }

    const {$t} = useLocaleStore();
    document.title = $t(to.meta.title ? to.meta.title as unknown as string : import.meta.env.VITE_APP_NAME);
});

export default router;

export function route(to: RouteToType): RouteLocationRaw {
    return to as RouteLocationRaw;
}
