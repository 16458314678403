import {FrontendLayoutsEnum} from "@/frontend/app/layouts/types";
import type {FrontendRouteRecordType} from "@/frontend/app/router/types";
import {ESubscriptionTariffOptionCode} from "@/frontend/entities/subscription";

export const calendarRoutes = [
    {
        path: '/calendar/circle',
        name: 'calendars.circle.create',
        component: () => import("@/frontend/pages/calendars/circle/CalendarCircleCreate.vue"),
        meta: {
            layout: FrontendLayoutsEnum.default,
            title: 'Creating calendar',
            requiresAuth: true,
            needSubscriptionOption: ESubscriptionTariffOptionCode.CALENDAR
        },
    },
    {
        path: '/calendar/circle/:id',
        name: 'calendars.circle.edit',
        component: () => import("@/frontend/pages/calendars/circle/CalendarCircleEdit.vue"),
        meta: {
            layout: FrontendLayoutsEnum.default,
            title: 'Editing calendar',
            requiresAuth: true,
            needSubscriptionOption: ESubscriptionTariffOptionCode.CALENDAR
        },
    },
] as const satisfies readonly FrontendRouteRecordType[];
