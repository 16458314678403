import {FrontendLayoutsEnum} from "@/frontend/app/layouts/types";
import type {FrontendRouteRecordType} from "@/frontend/app/router/types";

export const componentRoutes = [
    {
        path: '/components',
        name: 'components.index',
        component: () => import("@/frontend/pages/components/ComponentsIndex.vue"),
        meta: {
            layout: FrontendLayoutsEnum.default,
            title: 'Components',
            requiresAuth: true
        },
        children: [
            {
                path: '/components/different',
                name: 'components.different',
                component: () => import("@/frontend/pages/components/index/ComponentsDifferent.vue"),
                meta: {
                    layout: FrontendLayoutsEnum.default,
                    title: 'Different',
                    requiresAuth: true
                },
            },
            {
                path: '/components/buttons',
                name: 'components.buttons',
                component: () => import("@/frontend/pages/components/index/ComponentsButtons.vue"),
                meta: {
                    layout: FrontendLayoutsEnum.default,
                    title: 'Buttons',
                    requiresAuth: true
                },
            },
            {
                path: '/components/form',
                name: 'components.form',
                component: () => import("@/frontend/pages/components/index/ComponentsForm.vue"),
                meta: {
                    layout: FrontendLayoutsEnum.default,
                    title: 'Form',
                    requiresAuth: true
                },
            },
            {
                path: '/components/table',
                name: 'components.table',
                component: () => import("@/frontend/pages/components/index/ComponentsTable.vue"),
                meta: {
                    layout: FrontendLayoutsEnum.default,
                    title: 'Table',
                    requiresAuth: true
                },
            },
            {
                path: '/components/sandbox',
                name: 'components.sandbox',
                component: () => import("@/frontend/pages/components/index/ComponentsSandbox.vue"),
                meta: {
                    layout: FrontendLayoutsEnum.default,
                    title: 'Sandbox',
                    requiresAuth: true
                },
            }
        ]
    },
] as const satisfies readonly FrontendRouteRecordType[];
