import {FrontendLayoutsEnum} from "@/frontend/app/layouts/types";
import type {FrontendRouteRecordType} from "@/frontend/app/router/types";
import {ESubscriptionTariffOptionCode} from "@/frontend/entities/subscription";

export const companyRoutes = [
    {
        path: '/company',
        name: 'company',
        component: () => import("@/frontend/pages/company/CompanyRouterView.vue"),
        children: [
            {
                path: '/company/profile',
                name: 'company.profile.show',
                component: () => import("@/frontend/pages/company/CompanyProfile.vue"),
                meta: {
                    layout: FrontendLayoutsEnum.default,
                    title: 'Profile',
                    requiresAuth: true
                },
            },
            {
                path: '/company/products',
                name: 'company.products.index',
                component: () => import("@/frontend/pages/company/CompanyProductIndex.vue"),
                meta: {
                    layout: FrontendLayoutsEnum.default,
                    title: 'Products and services',
                    requiresAuth: true,
                    needSubscriptionOption: ESubscriptionTariffOptionCode.PROFILE_PRODUCTS
                },
            },
            {
                path: '/company/orders',
                name: 'company.orders.index',
                component: () => import("@/frontend/pages/company/CompanyProductOrderIndex.vue"),
                meta: {
                    layout: FrontendLayoutsEnum.default,
                    title: 'Requests',
                    requiresAuth: true,
                    needSubscriptionOption: ESubscriptionTariffOptionCode.PROFILE_ORDERS
                },
            },
            {
                path: '/company/reviews',
                name: 'company.reviews.index',
                component: () => import("@/frontend/pages/company/CompanyReviewIndex.vue"),
                meta: {
                    layout: FrontendLayoutsEnum.default,
                    title: 'Reviews',
                    requiresAuth: true
                },
            },
            {
                path: '/company/push-notifications',
                name: 'company.push-notifications.index',
                component: () => import("@/frontend/pages/company/CompanyPushNotificationIndex.vue"),
                meta: {
                    layout: FrontendLayoutsEnum.default,
                    title: 'Push notifications',
                    requiresAuth: true,
                    needSubscriptionOption: ESubscriptionTariffOptionCode.PROFILE_PUSH_NOTIFICATIONS
                },
            },
            {
                path: '/company/ad-notifications',
                name: 'company.ad-notifications.index',
                component: () => import("@/frontend/pages/company/CompanyAdNotificationIndex.vue"),
                meta: {
                    layout: FrontendLayoutsEnum.default,
                    title: 'Advertising notices',
                    requiresAuth: true,
                    needSubscriptionOption: ESubscriptionTariffOptionCode.PROFILE_AD_NOTIFICATIONS
                },
            },
        ]
    },
] as const satisfies readonly FrontendRouteRecordType[];
